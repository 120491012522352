<script>
  /**
   * A component that hijacks the rendering of its children to add classes and/or other attributes to them, or even wrap each of them in a new element.
   */
  export default {
    props: {
      /** The HTML tag used for the root element. */
      tag: {
        type: String,
        default: 'div',
      },
      /** The classes to put on the child elements. Can be a function which will be executed for each child (passing `nodeIndex` and `nodeCount`) and return the classes for that child. */
      childrenClasses: {
        type: [String, Function],
        default: undefined,
      },
      /** The attributes to put on the child elements. Works like `childrenClasses`, except that it accepts an object (e.g. `{ 'aria-label': 'Example' }`) or a function that returns an object. */
      childrenAttributes: {
        type: [Object, Function],
        default: undefined,
      },
      /** The HTML tag used to wrap each child element. Defaults to undefined which means child elements are not wrapped. Can be a function which will be executed for each child (passing `nodeIndex` and `nodeCount`) and return the tag for that child’s wrapper. */
      childrenWrapperTag: {
        type: [String, Function],
        default: undefined,
      },
      /** Only if `childrenWrapperTag` is used. The classes to put on the wrapper element of each child. Works like `childrenClasses`. */
      childrenWrapperClasses: {
        type: [String, Function],
        default: undefined,
      },
      /** Only if `childrenWrapperTag` is used. The attributes to put on the wrapper element of each child. Works like `childrenAttributes`. */
      childrenWrapperAttributes: {
        type: [Object, Function],
        default: undefined,
      },
    },
    methods: {
      getNodes() {
        // Only get the nodes with a tag
        const nodes = this.$scopedSlots.default?.()?.filter((node) => node.tag) ?? []
        const nodeCount = nodes.length
        return nodes.map((node, nodeIndex) => {
          const getPropForNode = (prop) => {
            if (typeof prop === 'function') {
              return prop({
                nodeIndex,
                nodeCount,
              })
            }
            return prop
          }
          return {
            node: node,
            classes: getPropForNode(this.childrenClasses),
            attributes: getPropForNode(this.childrenAttributes),
            wrapperTag: getPropForNode(this.childrenWrapperTag),
            wrapperClasses: getPropForNode(this.childrenWrapperClasses),
            wrapperAttributes: getPropForNode(this.childrenWrapperAttributes),
          }
        })
      },
    },
  }
</script>

<template>
  <component :is="tag">
    <template v-for="(node, nodeIndex) in getNodes()">
      <UtilityConditionalWrapper
        :key="nodeIndex"
        :tag="node.wrapperTag"
        :wrap="Boolean(node.wrapperTag)"
        :class="node.wrapperClasses"
        v-bind="node.wrapperAttributes"
      >
        <UtilityNode :node="node.node" :class="node.classes" v-bind="node.attributes" />
      </UtilityConditionalWrapper>
    </template>
  </component>
</template>
